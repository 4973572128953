.atlas-theme {
  .mainbar {
    &:focus {
      outline: none;
    }
  }
}
.loadericon {
  padding-right: 0.5rem;
  display: inline-block;
  cursor: pointer;
}
.fa-spinner-third {
  padding-right: 0rem !important;
}
.fa-circle {
  &:hover {
    color: #ff002a !important;
    text-shadow: #0088ff 0px 0px 2px;
  }
}
.loader-page.denied img {
  opacity: 1 !important;
}
.logo-container {
  width: 20rem !important;
}
.rotate-center {
  -webkit-animation: rotate-center 0.6s ease-in-out infinite both;
  animation: rotate-center 0.6s ease-in-out infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-5-3 13:0:10
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.max-contrast {
  background-color: #d9dee4 !important;
}
.sidebar .nav {
  display: none !important;
}
.master-detail {
  background-color: transparent !important;
  box-shadow: none !important;
}
.academic {
  .btn-primary {
    background-color: #001cbf;
    border-color: #001cbf;
  }
  .btn-outline-secondary {
    box-shadow: none !important;
    &:hover {
      color: unset !important;
    }
  }
}

.appName {
  max-width: 120px;
}

.loader-page {
  .btn.busy-indicator {
    box-shadow: none;
  }
}

.btn {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.07), 0px 4px 4px rgba(0, 0, 0, 0.07), 0px 8px 8px rgba(0, 0, 0, 0.07), 0px 16px 16px rgba(0, 0, 0, 0.07),
    0px 32px 32px rgba(0, 0, 0, 0.07);
}

.max-contrast .nav-icons .nav-link.active {
  background-color: #333 !important;
}

.onboarding-colors {
  @media screen and (max-width: 566px) {
    padding-top: 1rem;
  }

  > div {
    margin-bottom: 2rem;
  }

  .list-item {
    .list-group-item:focus {
      color: #ffffff;
    }
  }
}

.max-contrast .master-detail {
  background: #d9dee4 !important;
}

.duedate {
  font-weight: 600;
}

@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes blink-1 {
  0%,
  50%,
  100% {
    background-color: #ddd;
    border-color: #ddd;
  }
  25%,
  75% {
    background-color: #eee;
  }
}
.alert {
  display: inline-block !important;
}
.option-toggles {
  margin-bottom: 0.5rem;
  a.loading {
    -webkit-animation: blink-1 1.2s both;
    animation: blink-1 1.2s both;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    i {
      opacity: 0;
    }
    color: #888;
  }
  a {
    color: #001cbf;
    padding: 0.75rem;
    border-radius: 0.5rem;
    display: inline-block;
    text-align: center;
    margin: 0.25rem;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.07), 0px 4px 4px rgba(0, 0, 0, 0.07), 0px 8px 8px rgba(0, 0, 0, 0.07), 0px 16px 16px rgba(0, 0, 0, 0.07),
      0px 32px 32px rgba(0, 0, 0, 0.07);

    i {
      font-size: 2rem;
      opacity: 0.33;
      display: block;
      text-align: center;
      width: 100%;
      &:before {
        text-align: center;
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
  a.selected {
    box-shadow: none;
    color: #fff;
    background: #001cbf;
    border: 1px solid #001cbf;
    cursor: default;
  }

  a.loading {
    .busy-indicator {
      .loader {
        position: relative;

        .pulsate-loading {
          margin: 4px 0 5px 0;
        }
      }
    }
  }
}
.overdue {
  color: #ff002a;
  position: absolute;
  right: 0.5rem;
  margin-top: 0rem;
}
.overdue-hidden {
  display: none;
}

.Orientation {
  .btn-primary {
    &:hover {
      filter: brightness(85%) !important;
    }
  }
  .option-toggles {
    a {
      color: #7300de;
    }
    a.selected {
      color: #fff;
      background-color: #7300de;
      border-color: #7300de;
    }
  }
  .btn-outline-secondary {
    box-shadow: none !important;
    &:hover {
      color: unset !important;
    }
  }
}

.Academic {
  .option-toggles {
    a {
      color: #0088ff;
    }
    a.selected {
      color: #fff;
      background-color: #0088ff;
      border-color: #0088ff;
    }
  }
  .btn-primary {
    &:hover {
      filter: brightness(85%) !important;
    }
  }
  .btn-outline-secondary {
    box-shadow: none !important;
    &:hover {
      color: unset !important;
    }
  }
}

.Identification {
  .btn-primary {
    &:hover {
      filter: brightness(85%) !important;
    }
  }
  .btn-outline-secondary {
    box-shadow: none !important;
    &:hover {
      color: unset !important;
    }
  }
}

.Other {
  .option-toggles {
    a {
      color: #c308b3;
    }
    a.selected {
      color: #fff;
      background-color: #c308b3;
      border-color: #c308b3;
    }
  }
  .btn-primary {
    &:hover {
      filter: brightness(85%) !important;
    }
  }
  .btn-outline-secondary {
    box-shadow: none !important;
    &:hover {
      color: unset !important;
    }
  }
}

.Medical {
  .option-toggles {
    a {
      color: #109101 !important;
    }
    a.selected {
      color: #fff !important;
      background-color: #109101;
      border-color: #109101;
    }
  }
  .btn-primary {
    &:hover {
      filter: brightness(85%) !important;
    }
  }
  .btn-outline-secondary {
    box-shadow: none !important;
    &:hover {
      color: unset !important;
    }
  }
}

.Advising {
  .option-toggles {
    a {
      color: #006356;
    }
    a.selected {
      color: #fff;
      background-color: #006356;
      border-color: #006356;
    }
  }
  .btn-primary {
    &:hover {
      filter: brightness(85%) !important;
    }
  }
  .btn-outline-secondary {
    box-shadow: none !important;
    &:hover {
      color: unset !important;
    }
  }
}

.Training {
  .option-toggles {
    a {
      color: #383838;
    }
    a.selected {
      color: #fff;
      background-color: #383838;
      border-color: #383838;
    }
  }
  .btn-primary {
    &:hover {
      filter: brightness(85%) !important;
    }
  }
  .btn-outline-secondary {
    box-shadow: none !important;
    &:hover {
      color: unset !important;
    }
  }
}

.bold {
  font-weight: 600;
}

.completed {
  opacity: 0.7;
}

.progressbar-container {
  position: fixed;
  margin-top: -6rem;
  z-index: 1000;
  .progressbar {
    background: #ccc;
    height: 0.5rem;
    width: 200px;
    border-radius: 0.5rem;
    position: relative;
    .progress {
      background: #109101;
      height: 0.5rem;
    }
    .progressNumber {
      display: inline-block;
      position: absolute;
      padding: 0.1rem 0.4rem;
      border-radius: 0.8rem;
      color: #fff;
      margin-top: -16px;
      margin-left: -10px;
      font-size: 0.8rem;
      line-height: 17px;
      background: #109101;
      span {
        opacity: 0.68;
      }
    }
  }
}

.listed-progress {
  .progressbar-container {
    position: relative !important;
    margin-top: 0.5rem;
    margin-right: 1rem !important;
    line-height: 0.1rem;
    .progressbar {
      height: 3px;
      margin-top: 1rem !important;
      width: 150px !important;
    }
    .progress {
      height: 3px;
    }
  }
}

.Identification {
  .listed-progress .progressbar-container .progressbar .progress {
    background-color: #001cbf;
  }
  .listed-progress .progressNumber {
    margin-top: -12px !important;
  }
}
.Orientation {
  .listed-progress .progressbar-container .progressbar .progress {
    background-color: #7300de;
  }
  .listed-progress .progressNumber {
    margin-top: -12px !important;
  }
}
.Other {
  .listed-progress .progressbar-container .progressbar .progress {
    background-color: #c308b3;
  }
  .listed-progress .progressNumber {
    margin-top: -12px !important;
  }
}
.Academic {
  .listed-progress .progressbar-container .progressbar .progress {
    background-color: #0088ff;
  }
  .listed-progress .progressNumber {
    margin-top: -12px !important;
  }
}
.Medical {
  .listed-progress .progressbar-container .progressbar .progress {
    background-color: #109101;
  }
  .listed-progress .progressNumber {
    margin-top: -12px !important;
  }
}
.Advising {
  .listed-progress .progressbar-container .progressbar .progress {
    background-color: #006356;
  }

  .listed-progress .progressNumber {
    margin-top: -12px !important;
  }
}
.Training {
  .listed-progress .progressbar-container .progressbar .progress {
    background-color: #383838;
  }

  .listed-progress .progressNumber {
    margin-top: -12px !important;
  }
}

.not-applicable {
  i {
    opacity: 0;
  }
  opacity: 0.5;
}

.detail .header {
  height: 100px;
}

.OFY-welcome {
  img {
    // border-radius: 50%;
    width: 180px;
    margin-top: 2rem;
    margin-right: 2rem;
    height: auto;
  }
}

@media only screen and (max-width: 700px) {
  .progressbar-container {
    margin-left: 2rem;
  }
}

.toggleHidden {
  color: #333;
  margin-bottom: 0.5rem;
  display: block;
  text-align: right;
  margin-top: -40px;
  position: fixed;
  width: 100%;
  padding-right: 2rem;
  cursor: pointer;
  span {
    background: rgba(0, 0, 0, 0.1);
    padding: 0.05rem 0.25rem;
    border-radius: 0.33rem;
  }
  @media screen and (max-width: 566px) {
    margin-top: 2rem;
    position: relative;
    padding-left: 15px;
    margin-right: 15px;
    text-align: left;
  }
}

.list-group-item i:hover {
  color: #212529 !important;
  outline: none;
  text-shadow: none;
}

.detail-contents .actions {
  .btn {
    width: 100%;
  }
}

a .btn :hover {
  color: unset !important;
}

.skeleton-loader {
  margin-bottom: 0.5rem;
  animation: opacityPulse-2 1.5s ease-in-out 0.5s infinite;
  animation-iteration-count: infinite;
  display: block;
  background: rgba(0, 0, 0, 0.11);
  border-color: rgba(0, 0, 0, 0.11);
  border-radius: 0.6rem;
}

.master-detail .master .list-group.loading {
  padding-top: 20px;
  .skeleton {
    background: rgba(0, 0, 0, 0.11);
    border-color: rgba(0, 0, 0, 0.11);
  }
}

body {
  font-family: 'OpenSans';
  margin: 0;
  padding: 0px; // Example of adding global padding
}